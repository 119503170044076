const menuList = [
  {
    key: "/manage",
    title: "官网管理",
    children:[
      {
        key:'/manage/case',
        title: "案例管理",
      }
    ]
  },
  {
    key:'/test',
    title:"测试菜单"
  }
]
export default menuList