<template>
  <div class="header-top" >
    <!--  -->
    <div class="logo"><img src="../assets/img/logo.png" alt=""></div>
    <div class="header-right">
      <div class="flex-space"><img style="width: 40px;" src="../assets/img/avatar.png" alt="">张三</div>
      <div class="flex-space exit" @click="exit"><img src="../assets/img/loginout.png" alt="">退出</div>
    </div>
  </div>
  <div class="content">
    <div class="left-aside">
      <a-menu mode="inline"
      theme="dark"
      @click="handleClick"
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys">

          <!-- <a-sub-menu key="/manage">
            <template #title>官网管理</template>
            <a-menu-item key="/manage/case">案例管理</a-menu-item>
          </a-sub-menu> -->

        <template v-for="item in menuList">
          <template v-if="item.children && item.children.length > 0">
            <a-sub-menu :key="item.key">
              <template #title>{{ item.title }}</template>
              <a-menu-item v-for="it in item.children" :key="it.key">{{ it.title }}</a-menu-item>
            </a-sub-menu>
          </template>
          <template v-else>
            <a-menu-item :key="item.key">{{ item.title }}</a-menu-item>
          </template>
        </template>
      </a-menu>
    </div>
    <div class="page">
      <p v-if="pageName" class="titleName">{{ pageName }}</p>
      <div style="background-color: #fff;">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script setup>
import menuList from "./menuList.js";
import {reactive,toRefs,getCurrentInstance,onMounted,watch} from 'vue'
 import {useRouter,useRoute} from 'vue-router'
 const router = useRouter()
 const route = useRoute()
 const { proxy } = getCurrentInstance()
  let $api = proxy.$api
 const state = reactive({
  openKeys:['/manage'],
  selectedKeys:['/manage/case'],
  pageName:''
 })
 const {openKeys,selectedKeys,pageName}=toRefs(state)

 const exit = ()=>{
  $api.logout().then(res=>{
    router.push({path:'/login'})
  })
 }

 watch(()=>router.currentRoute.value.path,()=>{
   menuList.map((item)=>{
     if(state.selectedKeys[0] == item.key){
       state.pageName = item.title;
     }
     if(router.currentRoute.value.path == item.key){
      state.selectedKeys = [item.key]
     }
   })
   if(route.meta&&route.meta.NoPageName){
     state.pageName = ''
   }
},{ immediate: true })

 onMounted(()=>{
 })

const handleClick = (e)=>{
  router.push({path:e.key})
  state.selectedKeys = [e.key]
  state.pageName =e.item.title
}
</script>
<style lang="less" scoped>
.menu{
    // width: 180px;
    .ant-menu-item{
        padding-right: 16px;
        
    }
    .ant-menu-submenu{
        .ant-menu-submenu-title{
            &>span{
                img{
                    padding: 0 5px  5px 0;
                }
            }
        }
    }
   }
.page{
  height: calc(100vh - 54px);
  overflow-y: scroll;
  flex: 1;
  margin-left:167px;
}
.left-aside{
  width: 155px;
  height: calc(100vh - 54px);
  background: #001529;
  position:fixed;
  bottom:0;
  left:0;
}
.titleName{
  font-size: 14px;
  color: #333;
  height: 52px;
  line-height: 52px;
  margin: 0;
}
.header-top{
  text-align: right;
  background-color: #fff;
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    box-shadow:0 7px 15px -5px #ddd;
    .logo{
      display: flex;
      align-items: center;
    }
    .logo img{
      width: 200px;
      margin-left: 22px;
    }
    .header-right{
      display: flex;
      align-items: center;
      .exit{
        cursor: pointer;
      }
      .flex-space{
        margin-right: 30px;
        img{
          margin-right: 10px;
        }
      }
    }
}
.content{
  display: flex;
  align-content: space-between;
  padding-top: 54px;
}
</style>